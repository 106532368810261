import request from '../utils/request'
import baseURL from '../utils/config'

//新增或者删除
export function AddOrEdit(data) {
    return request({
        url: baseURL.baseURL + 'api/Adm_Company/AddOrEdit',
        method: 'post',
        data
    })
}


//新增或者删除
export function AddOrEdit2(data) {
    return request({
        url: baseURL.baseURL + 'api/Adm_Company/AddOrEdit',
        method: 'post',
        data
    })
}

//得到详情
export function GetDetail(params) {
    return request({
        url: baseURL.baseURL + 'api/Adm_Company/GetDetail',
        method: 'get',
        params
    })
}
//得到列表数据
export function GetList(data) {
    return request({
        url: baseURL.baseURL + 'api/Adm_Company/GetList',
        method: 'post',
        data
    })
}

//删除
export function Delete(params) {
    return request({
        url: baseURL.baseURL + 'api/Adm_Company/Delete',
        method: 'get',
        params
    })
}

//设置是否启用
export function SetEnable(params) {
    return request({
        url: baseURL.baseURL + 'api/Adm_Company/SetEnable',
        method: 'get',
        params
    })
}
//设置寄货前是否需要实名
export function SetRealNameAuthentication(params) {
    return request({
        url: baseURL.baseURL + 'api/Adm_Company/SetRealNameAuthentication',
        method: 'get',
        params
    })
}

//设置部分下架
export function DisableToMerchant(data) {
    return request({
        url: baseURL.baseURL + 'api/Adm_Company/DisableToMerchant',
        method: 'post',
        data
    })
}

//得到已经下架的关系
export function DisableToMerchantGetInfoList(params) {
    return request({
        url: baseURL.baseURL + 'api/Adm_Company/DisableToMerchantGetInfoList',
        method: 'get',
        params
    })
}

//得到商家
export function GetMerchantList(params) {
    return request({
        url: baseURL.baseURL + 'api/Adm_Company/GetMerchantList',
        method: 'get',
        params
    })
}

