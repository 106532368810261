<template>
  <div v-loading="loading">
    <!-- 搜索 -->
    <div class="toolbar">
      物流公司：
      <el-input
        class=""
        placeholder="请输入名称"
        v-model="queryParam.companyName"
        size="medium"
        clearable
      >
      </el-input>
      启用状态
      <el-select v-model="queryParam.enableType" placeholder="请选择">
        <el-option label="全部" :value="0"></el-option>
        <el-option label="启用" :value="1"></el-option>
        <el-option label="未启用" :value="2"></el-option>
      </el-select>
      <el-button
        type="primary"
        size="medium"
        icon="el-icon-search"
        style="margin-left: 10px"
        @click="btn('query')"
        >查询</el-button
      >
      <el-button size="medium" @click="btn('reset')">重置</el-button>
    </div>
    <!-- 表格数据 -->
    <div class="right">
      <el-button type="primary" size="medium" @click="btn('add')"
        >新增</el-button
      >
    </div>
    <div class="divTb">
      <el-table
        :data="tableData"
        :header-cell-style="{
          background: '#cce1f9',
          color: '#505050',
          textAlign: 'center',
        }"
        :cell-style="{ textAlign: 'center' }"
        style="width: 100%"
      >
        <el-table-column prop="companyId" label="编号"> </el-table-column>
        <el-table-column prop="name" label="公司名称"> </el-table-column>
        <el-table-column prop="contractorUser" label="承运人">
        </el-table-column>
        <el-table-column prop="mobile" label="联系方式"> </el-table-column>
        <el-table-column prop="remark" label="配送描述"> </el-table-column>
        <el-table-column prop="postTime" label="添加时间">
          <template slot-scope="scope">
            {{ dateFormat("YYYY-mm-dd HH:MM:SS", scope.row.postTime) }}
          </template>
        </el-table-column>
        <el-table-column label="是否启用">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.enable"
              active-text="开"
              inactive-text="关"
              @change="btn('enable', scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>

        <el-table-column label="生鲜邮寄">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.mailType"
              active-text="开"
              inactive-text="关"
              @change="mailchange(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="寄件前是否需要实名">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isRealNameAuthentication"
              active-text="开"
              inactive-text="关"
              @change="btn('isRealNameAuthentication', scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="btn('edit', scope.row.companyId)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="text"
              @click="btn('DisableToMerchant', scope.row.companyId)"
              >设置部分下架</el-button
            >
            <!-- <el-button
              size="mini"
              type="text"
              @click="btn('delete', scope.row.companyId)"
              >删除</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParam.index"
        :page-size="queryParam.size"
        :page-sizes="[5, 10, 15, 20, 50]"
        layout="total, prev, pager, next,sizes"
        :total="queryParam.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="visible.dialog"
      @close="closeDialog"
    >
      <AddOrEdit
        :funcSucc="closeDialog"
        :editId="compParam.AddOrEdit.editId"
        v-if="visible.AddOrEdit"
      />
      <DisableToMerchant
        :funcSucc="closeDialog"
        :companyId="compParam.DisableToMerchant.companyId"
        v-if="visible.DisableToMerchant"
      />
    </el-dialog>
  </div>
</template>

<script>
import request from "../../utils/request";
import baseURL from "../../utils/config";

import {
  GetList,
  Delete,
  SetEnable,
  SetRealNameAuthentication,
  AddOrEdit2
} from "@/api/Company";
import AddOrEdit from "@/components/LogisticsCompany/AddOrEdit";
import DisableToMerchant from "@/components/LogisticsCompany/DisableToMerchant";
export default {
  data() {
    return {
      loading: false,
      //查询参数
      queryParam: {
        companyName: "",
        enableType: 0,
        size: 10, //每页的数据量
        index: 1, //当前页数
        total: 1, //数据总量
      },
      //控制显示
      visible: {
        dialog: false,
        AddOrEdit: false,
        DisableToMerchant: false,
      },
      dialogTitle: "新增",
      //组件参数存放地
      compParam: {
        AddOrEdit: {
          editId: 1234,
        },
        DisableToMerchant: {
          companyId: 1234,
        },
      },
      tableData: [],
      currentPage: 1,
    };
  },
  components: {
    AddOrEdit,
    DisableToMerchant,
  },
  methods: {
    mailchange(val) {
      AddOrEdit2(val).then((res) => {
        console.log(res);
        this.loadData();
      });
    },

    //pageSize 改变时会触发
    btn(btnType, val) {
      switch (btnType) {
        case "query":
          this.loadData();
          console.log("查询");
          break;
        case "reset":
          this.queryParam.companyName = "";
          this.queryParam.enableType = 0;
          this.loadData();
          console.log("重置");
          break;
        case "add":
          this.dialogTitle = "新增";
          this.compParam.AddOrEdit.editId = null;
          this.visible.AddOrEdit = true;
          this.visible.dialog = true;
          console.log("新增");
          break;
        case "edit":
          this.dialogTitle = "编辑";
          this.compParam.AddOrEdit.editId = val;
          this.visible.AddOrEdit = true;
          this.visible.dialog = true;
          console.log("编辑");
          break;
        case "delete":
          this.delete(val);
          console.log("删除");
          break;
        case "enable":
          this.Enable(val);
          console.log("启用");
          break;
        case "DisableToMerchant":
          this.dialogTitle = "设置部分下架";
          this.compParam.DisableToMerchant.companyId = val;
          this.visible.dialog = true;
          this.visible.DisableToMerchant = true;
          console.log("设置部分下架");
          break;
        case "isRealNameAuthentication":
          this.IsRealNameAuthentication(val);
          console.log("是否实名");
          break;
      }
    },
    closeDialog(val) {
      console.log("关闭");
      this.loadData();
      this.visible.dialog = false;
      this.visible.AddOrEdit = false;
      this.visible.DisableToMerchant = false;
    },
    Enable(val) {
      let tip = "确定启用吗";
      if (val.enable == false) {
        tip = "确定禁用吗";
      }

      this.$confirm(tip, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          SetEnable({ id: val.companyId, enable: val.enable })
            .then((res) => {
              this.loading = false;
              if (res.code == 10000) {
                this.$message.success("设置成功!");
                this.loadData();
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch(() => {
          val.enable = !val.enable;
        });
    },
    IsRealNameAuthentication(val) {
      let tip = "确定启用吗";
      if (val.isRealNameAuthentication == false) {
        tip = "确定禁用吗";
      }

      this.$confirm(tip, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          SetRealNameAuthentication({
            id: val.companyId,
            enable: val.isRealNameAuthentication,
          })
            .then((res) => {
              this.loading = false;
              if (res.code == 10000) {
                this.$message.success("设置成功!");
                this.loadData();
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch(() => {
          val.isRealNameAuthentication = !val.isRealNameAuthentication;
        });
    },
    delete(id) {
      //删除
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        Delete({ id: id })
          .then((res) => {
            this.loading = false;
            if (res.code == 10000) {
              this.$message.success("删除成功!");
              this.loadData();
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      });
    },
    loadData() {
      this.loading = true;
      let postData = {
        pageSize: this.queryParam.size,
        pageIndex: this.queryParam.index,
        queryStr: this.queryParam.companyName,
        state: this.queryParam.enableType,
      };
      GetList(postData)
        .then((res) => {
          if (res.code == 10000) {
            this.tableData = res.data;
            this.queryParam.total = res.total;
            this.loading = false;
          } else {
            this.$message.error(res.msg);
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
      this.loading = false;
    },
    dateFormat(fmt, date) {
      date = new Date(date);
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.queryParam.size = val;
      this.loadData();
    },
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.queryParam.index = val;
      this.loadData();
    },
  },
  created: function () {
    //如果是修改，就加载数据
    this.loadData();
    if (this.$store.getters.roleId != 1) {
      this.$router.push({
        path: "/shipments",
      });
    }
  },
};
</script>

<style scope>
.toolbar {
  background: #f2f2f2;
  padding: 10px;
  margin: 10px 0;
}
.divTb {
  margin-top: 20px;
}
.el-pagination {
  text-align: center;
  margin-top: 13px;
}
.el-input {
  width: 300px;
  margin-right: 10px;
}
.right {
  float: right;
  padding-right: 50px;
  padding-bottom: 10px;
}
</style>