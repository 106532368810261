<template>
  <div v-loading="loading">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="公司名称" prop="name">
        <el-input
          v-model="ruleForm.name"
          maxlength="16"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="承运人" prop="contractorUser">
        <el-input
          v-model="ruleForm.contractorUser"
          maxlength="16"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="联系方式" prop="mobile">
        <el-input
          v-model="ruleForm.mobile"
          maxlength="11"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="折扣比例" prop="discount">
        <el-input v-model="ruleForm.discount"></el-input>
      </el-form-item>
      <el-form-item label="配送描述">
        <el-input
          type="textarea"
          :rows="3"
          placeholder="请输入内容"
          v-model="ruleForm.remark"
          maxlength="200"
          show-word-limit
        >
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="btn('cancel')">取消</el-button>
        <el-button type="primary" @click="btn('submit')">确认</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { AddOrEdit, GetDetail } from "@/api/Company";

export default {
  props: {
    funcSucc: {
      type: Function,
    },
    editId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      ruleForm: {
        name: "", //公司名称
        contractorUser: "", //承运人
        mobile: "", //联系方式
        discount: null, //折扣比例
        remark: "", //配送描述
      },
      rules: {
        name: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
        contractorUser: [
          { required: true, message: "请输入承运人名称", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "请填写联系方式", trigger: "blur" },
          {
            pattern: /^1\d{10}$/,
            message: "请填写正确格式的联系方式",
            trigger: "blur",
          },
        ],
        discount: [
          { required: true, message: "请填写折扣比例", trigger: "blur" },
          {
            pattern: /^[1-9]$|^\d\.\d{1,3}$|^10$/,
            message:
              "请填写正确格式的折扣比例(例如  1：一折，9.5：九五折，10：不打折)",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    //pageSize 改变时会触发
    btn(btnType) {
      switch (btnType) {
        case "cancel":
          this.funcSucc();
          console.log("取消");
          break;
        case "submit":
          this.$refs["ruleForm"].validate((valid) => {
            if (valid) {
              this.loading = true;
              const { name, contractorUser, mobile, discount, remark } =
                this.ruleForm;
              if (discount <= 0) {
                this.$message.error("折扣不能小于等于0");
                this.loading = false;
                return;
              }
              let postData = {
                name: name,
                contractorUser: contractorUser,
                mobile: mobile,
                discount: discount,
                remark: remark,
                companyId: this.editId && this.editId > 0 ? this.editId : 0, //如果是修改行为，就给主键id赋值
              };
              AddOrEdit(postData)
                .then((res) => {
                  if (res.code == 10000) {
                    this.$message.success(res.msg);
                    this.loading = false;
                    this.funcSucc();
                  } else {
                    this.$message.error(res.msg);
                    this.loading = false;
                  }
                })
                .catch((err) => {
                  this.loading = false;
                });
            } else {
              console.log("登录表单填写信息填写有误!");
            }
          });
          break;
      }
    },
    loadData() {
      //开始加载数据
      GetDetail({ id: this.editId })
        .then((res) => {
          if (res.code == 10000 && res.data) {
            this.ruleForm.name = res.data.name;
            this.ruleForm.contractorUser = res.data.contractorUser;
            this.ruleForm.mobile = res.data.mobile;
            this.ruleForm.discount = res.data.discount;
            this.ruleForm.remark = res.data.remark;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {});
    },
  },
  created: function () {
    //如果是修改，就加载数据
    if (this.editId && this.editId > 0) {
      this.loadData();
    }
  },
};
</script>

<style scope>
.toolbar {
  background: #f2f2f2;
  padding: 10px;
  margin: 10px 0;
}
.divTb {
  margin-top: 20px;
}
.el-pagination {
  text-align: center;
  margin-top: 13px;
}
.el-input {
  width: 300px;
  margin-right: 10px;
}
.right {
  float: right;
  padding-right: 50px;
  padding-bottom: 10px;
}
</style>